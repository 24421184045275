import * as React from 'react';
import {Backdrop, CircularProgress, FormControl, InputLabel, MenuItem, Stack, Select, Typography, SelectChangeEvent} from '@mui/material';

import KetchwordBasicForm from "./KetchwordBasicForm";
import KetchwordBasicPreview from "./KetchwordBasicPreview";
import RockitFuelPreview from "./RockitFuelPreview";
import RockitFuelForm from "./RockitFuelForm";
import { CallToActionKind, CampaignType } from './Props';
import './KetchwordBasic.css';


const KetchwordsBasic = (props: {campaignType: CampaignType}) => {
    /* Common State */
    const [bio, setBio] = React.useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet justo donec enim diam vulputate. Pretium viverra suspendisse potenti nullam ac. ');
    const [intro, setIntro] = React.useState('My First Intro');
    const [title, setTitle] = React.useState('10 Ways to Write HTML');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [attachmentUrl, setAttachmentUrl] = React.useState('');
    const [attachmentFiles, setAttachmentFiles] = React.useState<FileList | null>(null);
    const [ketchword, setKetchword] = React.useState('DEMO-123');
    const [company, setCompany] = React.useState('');
    const [product, setProduct] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [emailAddressLink, setEmailAddressLink] = React.useState<URL | string>("user@example.org");
    const [logoUrl, setLogoUrl] = React.useState('');
    const [logoFiles, setLogoFiles] = React.useState<FileList | null>(null);
    const [cta, setCta] = React.useState<{kind: CallToActionKind, value: URL | string}>(
        (props.campaignType === "rockit-fuel") ?
        {kind: 'url' as CallToActionKind, value: "https://rockit-fuel.simplecast.com/"} :
        {kind: 'email' as CallToActionKind, value: "user@example.org"} 
    );

    /* Rock-It Fuel State */
    const [episodeTitle, setEpisodeTitle] = React.useState('');

    // Wire-up event handler to ensure the form is reset after revisiting the page
    // Particularly if navigated to the page via Back
    window.addEventListener('pageshow', (evt:PageTransitionEvent) => {
        setBio('');
        setIntro('');
        setTitle('');
        setFirstName('');
        setLastName('');
        setAttachmentUrl('');
        setAttachmentFiles(null);
        setKetchword('');
        setCompany('');
        setProduct('');
        setEmailAddress('');
        setEmailAddressLink('');
        setLogoUrl('');
        setLogoFiles(null);
        setEpisodeTitle('');
        setCta({kind: 'email' as CallToActionKind, value: ''});
    });

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    /**
     * Default event handling behavior for the Call-to-Action Type selection
     * @param e event data
     */
    const defaultCtaSelectionChangeHandler = (e: SelectChangeEvent<CallToActionKind>) => {
        let url = (e.target.value === 'email') ? "user@example.org" : "https://example.org";
        setCta({kind: e.target.value as CallToActionKind, value: url});
        setEmailAddressLink(url);
    };

    /**
     * Default event handler for Call-to-Action Target value entry
     * @param e event data
     */
    const defaultCtaActionValueChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        var url: URL | string;
                try {
                    url = new URL(e.target.value);
                } catch {
                    url = e.target.value;
                }
                setCta({kind: cta.kind, value: url});
                setEmailAddressLink(url);
    };

    /**
     * Default event handler for onSave action
     * Toggles the overlay in form after save has started
     */
    const defaultOnSaveHandler = (started: boolean) => { handleToggle(); }

    /**
     * Default event handler for onSaveComplete action
     * Redirects the person to a page to describe success or failure
     * @param success 
     */
    const defaultOnSaveCompleteHandler = (success:boolean) => { 
        // TODO(jsh): Wait here for system submission to settle before providing user with feedback
        if(success) { 
            window.location.assign('http://builder.ketchwords.com/processing');
        } else { 
            window.location.assign('https://example.org/save-failure');
        } 
    };

    const BASIC_FORM = 
        <KetchwordBasicForm
            data={{campaignType: "basic", bio, intro, title, attachmentUrl, firstName, lastName, ketchword, company, product, emailAddress, attachmentFiles, emailAddressLink, logoUrl, logoFiles, cta}}
            onBioChange={e => setBio(e.target.value)}
            onIntroChange={e => setIntro(e.target.value)}
            onTitleChange={e => setTitle(e.target.value)}
            onFirstNameChange={e => setFirstName(e.target.value)}
            onLastNameChange={e => setLastName(e.target.value)}
            onCompanyChange={e=> setCompany(e.target.value)}
            onProductChange={e=> setProduct(e.target.value)}
            onEmailAddressChange={e=> {setEmailAddress(e.target.value); setEmailAddressLink(e.target.value)}}
            onAttachmentChange={e=>setAttachmentUrl(e.target.value)}
            onAttachmentFilesChange={e=>{setAttachmentFiles(e.target.files)}}
            onKetchwordChange={e => setKetchword(e.target.value)}
            onLogoChange={e => setLogoUrl(e.target.value)}
            onLogoFilesChange={e => setLogoFiles(e.target.files)}
            onCallToActionSelectChange={defaultCtaSelectionChangeHandler}
            onCallToActionValueChange={defaultCtaActionValueChangeHandler}
            onSave={defaultOnSaveHandler}
            onSaveComplete={defaultOnSaveCompleteHandler}
        />;

        const ROCKIT_FUEL_FORM = <RockitFuelForm
                data={{campaignType: "rockit-fuel", bio, intro, title, attachmentUrl, firstName, 
                    lastName, ketchword, company, product, emailAddress, attachmentFiles, 
                    emailAddressLink, logoUrl, logoFiles, cta: {kind: 'url' as CallToActionKind, value: "https://rockit-fuel.simplecast.com/"}, episodeTitle}}
                onBioChange={e => setBio(e.target.value)}
                onIntroChange={e => setIntro(e.target.value)}
                onTitleChange={e => setTitle(e.target.value)}
                onFirstNameChange={e => setFirstName(e.target.value)}
                onLastNameChange={e => setLastName(e.target.value)}
                onCompanyChange={e=> setCompany(e.target.value)}
                onProductChange={e=> setProduct(e.target.value)}
                onEmailAddressChange={e=> {setEmailAddress(e.target.value); setEmailAddressLink(e.target.value)}}
                onAttachmentChange={e=>setAttachmentUrl(e.target.value)}
                onAttachmentFilesChange={e=>{setAttachmentFiles(e.target.files)}}
                onKetchwordChange={e => setKetchword(e.target.value)}
                onLogoChange={e => setLogoUrl(e.target.value)}
                onLogoFilesChange={e => setLogoFiles(e.target.files)} 
                onCallToActionSelectChange={defaultCtaSelectionChangeHandler}
                onCallToActionValueChange={defaultCtaActionValueChangeHandler}
                onEpisodeTitleChange={e => setEpisodeTitle(e.target.value)}
                onSave={defaultOnSaveHandler}
                onSaveComplete={defaultOnSaveCompleteHandler}
        />;

        const BASIC_PREVIEW = <KetchwordBasicPreview
            data={{campaignType: "basic", bio, intro, title, attachmentUrl, firstName, lastName, ketchword, company, product, emailAddress, attachmentFiles, emailAddressLink, logoUrl, logoFiles, cta}}
        />;

        const ROCKIT_FUEL_PREVIEW = (
            <RockitFuelPreview
                data={{campaignType: "rockit-fuel", bio, intro, title, attachmentUrl, firstName, 
                    lastName, ketchword, company, product, emailAddress, attachmentFiles, 
                    emailAddressLink, logoUrl, logoFiles, cta: {kind: 'url' as CallToActionKind, value: "https://rockit-fuel.simplecast.com/"}, episodeTitle}}
            >
            </RockitFuelPreview>
        );

        let form = BASIC_FORM;
        var preview = BASIC_PREVIEW;
        switch(props.campaignType) {
            case "basic":
                form = BASIC_FORM;
                preview = BASIC_PREVIEW;
                break;
            case "rockit-fuel":
                form = ROCKIT_FUEL_FORM;
                preview = ROCKIT_FUEL_PREVIEW;
                break;
            default:
                console.error("Unexpected campaign type: " + props.campaignType);
        }
        return (
            <div className="KetchwordBasic">
                {form}
                {preview}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <Stack direction={'row'} spacing={3}>
                    <CircularProgress color="inherit" />
                    <Typography>We are saving and activating your Ketchword now ... Please be patient, this process can take a few moments.</Typography>  
                    </Stack>
                </Backdrop>
            </div>
        );
}

export default KetchwordsBasic;