import * as React from 'react';
import {CallToActionKind, KetchwordBasicProps, RockitFuelProps} from './Props';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import {FormEvent, ReactNode} from "react";
import { campaignSubmissionHandler } from './CampaignSubmissionHandler';

interface DataProps {
    data: KetchwordBasicProps;
    onIntroChange: React.ChangeEventHandler<HTMLTextAreaElement>;
    onTitleChange: React.ChangeEventHandler<HTMLInputElement>;
    onBioChange: React.ChangeEventHandler<HTMLTextAreaElement>;
    onFirstNameChange: React.ChangeEventHandler<HTMLInputElement>;
    onLastNameChange: React.ChangeEventHandler<HTMLInputElement>;
    onCompanyChange: React.ChangeEventHandler<HTMLInputElement>;
    onProductChange: React.ChangeEventHandler<HTMLInputElement>;
    onEmailAddressChange: React.ChangeEventHandler<HTMLInputElement>;
    onAttachmentChange: React.ChangeEventHandler<HTMLInputElement>;
    onAttachmentFilesChange: React.ChangeEventHandler<HTMLInputElement>;
    onKetchwordChange: React.ChangeEventHandler<HTMLInputElement>;
    onLogoChange: React.ChangeEventHandler<HTMLInputElement>;
    onLogoFilesChange: React.ChangeEventHandler<HTMLInputElement>;
    onCallToActionSelectChange: (event: SelectChangeEvent<CallToActionKind>) => void;
    onCallToActionValueChange: React.ChangeEventHandler<HTMLInputElement>;
    onSave: (started: boolean) => void;
    onSaveComplete: (success: boolean) => void;
}


const KetchwordsBasicForm: React.FC<DataProps> = props => {
    
    const AVG_WORD_LENGTH_EN = 5;
    const KETCHWORD_LENGTH_MAX = 12;
    const BIO_LENGTH_MAX = 500;
    const INTRO_LENGTH_MAX = 500;
    const TITLE_LENGTH_MAX = 100;

    const handleIntroChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {props.onIntroChange(e);};
    const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onTitleChange(e)};
    const handleBioChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {props.onBioChange(e)};
    const handleFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onFirstNameChange(e)};
    const handleLastNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onLastNameChange(e)};
    // const handleAttachmentChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onAttachmentChange(e)};
    // const handleLogoChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onLogoChange(e)};
    const handleKetchwordChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onKetchwordChange(e)};
    const handleProductChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onProductChange(e)};
    const handleCompanyChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onCompanyChange(e)};
    const handleEmailAddressChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onEmailAddressChange(e)};
    const handleAttachmentFilesChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onAttachmentFilesChange(e)};
    const handleLogoFilesChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onLogoFilesChange(e)};
    const handleCallToActionSelectChange: (event: SelectChangeEvent<CallToActionKind>, child?: ReactNode) => void = e => {props.onCallToActionSelectChange(e)};
    const handleCallToActionValueChange: React.ChangeEventHandler<HTMLInputElement> = e => {props.onCallToActionValueChange(e)};


    /**
     * Calculates a percent value
     * @param value a current value
     * @param limit a upper limit
     * @return a percent integer
     */
    const calcPercent = (value: number, limit: number): number => {
        return (value / limit) * 100;
    }

    return (
      <form id={"ketchword-campaign-form"} className="campaign-form" onSubmit={campaignSubmissionHandler(props, props.onSave, props.onSaveComplete)}>
          <h2>Make Any Changes Below</h2>
          <Stack spacing={7}  className="form-content">
            <Stack spacing={3}>
            <Stack spacing={2} direction="row">
                <TextField id="firstName" label="First Name" fullWidth required variant="outlined" value={props.data.firstName} onChange={handleFirstNameChange}/>
                <TextField id="lastName" label="Last Name" fullWidth required variant="outlined" value={props.data.lastName} onChange={handleLastNameChange}/>
            </Stack>
            <TextField id="emailAddress" label="Email Address" required type={"email"} variant="outlined" value={props.data.emailAddress} onChange={handleEmailAddressChange} />
            <Stack spacing={3} direction="row">
                <TextField id="companyName" label="Company" fullWidth required variant="outlined" value={props.data.company} onChange={handleCompanyChange} />
                <TextField id="productName" label="Product Name" fullWidth required variant="outlined" value={props.data.product} onChange={handleProductChange} />
                <TextField id="ketchword" label="Ketchword" fullWidth required variant="outlined" value={props.data.ketchword} onChange={handleKetchwordChange}/>
            </Stack> 

            <label htmlFor="logo" className="attachment">Provide a file for company logo: (1 MB max) *<br/>
                <input type="file" id="logo" name="logo" required onChange={handleLogoFilesChange} accept=".jpg,.jpeg,.gif,.png,image/png,image/gif,image/jpeg"/>
            </label>
            </Stack>
            
            <Stack spacing={3}>
            <label htmlFor="attachment" className="attachment">Provide a file for attachment: (8MB max) *<br/>
                <input type="file" id="attachment" name="attachment" required onChange={handleAttachmentFilesChange} accept=".jpeg,.jpg,.gif,.png,.mp3,.mp4,.m4v,.mov,.pdf,.doc,.docx,.xls,.xlsx,image/jpeg,image/gif,image/png,audio/mpeg,video/mp4,video/x-m4v,video/quicktime,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
            </label>
            <Typography variant="h6">Enter the Title of Your Attachment: (100 Character Max)</Typography>
            <Stack spacing={3} direction={'row'}>
                <TextField id="title" label="Attachment Title" multiline fullWidth required helperText="" value={props.data.title} onChange={handleTitleChange} inputProps={{maxLength: TITLE_LENGTH_MAX}} />
            </Stack>
            </Stack>
            <Stack spacing={3}>
                <Typography variant="h6">Provide an Intro to Your Attachment: (500 Character Max)</Typography>
                <Stack spacing={3} direction={'row'}>
                    <TextField id="intro" required multiline fullWidth helperText="You've gotten them this far! Now write a description of your attachment that will entice your Subscriber to actually read it! Discuss the problem and how this attachment will solve it." label="Attachment Intro" value={props.data.intro} onChange={handleIntroChange} inputProps={{maxLength: INTRO_LENGTH_MAX}}/>
                    <Typography>{INTRO_LENGTH_MAX - props.data.intro.length}</Typography>
                </Stack>
                <Typography variant="h6">Enter Your Bio: (500 Character Max)</Typography>
                <Stack spacing={3} direction={'row'}>
                    <TextField id="bio" multiline required label="Bio" fullWidth helperText="Remind your Subscriber of your mission, who you serve and how you help them." value={props.data.bio} onChange={handleBioChange} inputProps={{maxLength: BIO_LENGTH_MAX}}/>
                    <Typography>{BIO_LENGTH_MAX - props.data.bio.length}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={3}>
                <Typography variant="h6">Provide a call-to-action:</Typography>
                <FormControl fullWidth>
                    <InputLabel id="call-to-action-label">Call-to-Action Type</InputLabel>
                    <Select
                        labelId="call-to-action-label"
                        id="call-to-action-select"
                        value={props.data.cta.kind}
                        label="Call-to-Action Kind"
                        onChange={handleCallToActionSelectChange}
                    >
                        <MenuItem value={'email' as CallToActionKind}>Email</MenuItem>
                        <MenuItem value={'url' as CallToActionKind}>URL</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="ctaValue" required label="Call-to-Action Target" fullWidth value={props.data.cta.value} onChange={handleCallToActionValueChange} inputProps={{maxLength: 255}}/>
            </Stack>
            <Button variant="contained" type={"submit"}>Submit</Button>
          </Stack>
      </form>
    );
}

export default KetchwordsBasicForm;