import * as React from 'react';
import {DataProps, RockitFuelProps} from "./Props";
import "./RockitFuel.css";

const RockitFuelPreview: React.FC<DataProps<RockitFuelProps>> = props => {

    const transformLineBreaks = (input: string): string => {
        return input.replaceAll("<", "&lt;")
            .replaceAll(">", "&gt;")
            .replaceAll("\n", "<br/>");
    };

    const previewLogo = (file: File): void => {
        const reader = new FileReader();
        const preview = document.querySelector<HTMLImageElement>("img#logo");
        reader.addEventListener("load", () => {
            if (preview){
                preview.src = reader.result ? reader.result.toString() : "";
            }            
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    if (props?.data?.logoFiles?.[0]) {
        previewLogo(props.data.logoFiles[0]);
    }

    return (
        <div className="ketchwords-main rockit-fuel">
            <h2>View Your Draft</h2>
            <div className="preview-content">
                <img src="https://files.constantcontact.com/bcd01438001/84e9c4f8-7536-4499-9d54-447eae4522c9.png?rdr=true"/>
                <div className="thanks-banner">
                <span>Thanks for texting {props.data.ketchword.toLocaleUpperCase()} to 411321<br/>
                    <span className="subline">(1-909-741-1321 via text or WhatsApp)</span></span>
                </div>
                

                <div className="intro-title">ATTACHED IS YOUR REQUEST FROM OUR GUEST!</div>

                <div className="episode-container">
                    <div className="episode-title"><strong>Episode Title:</strong><br/>{props.data.episodeTitle}</div>
                    <div className="guest-name"><strong>Guest Name:</strong><br/>{props.data.firstName} {props.data.lastName}</div>
                    <div className="your-logo"><img alt="Company Logo" id="logo" src="https://files.constantcontact.com/bcd01438001/4cd92381-1f5d-4935-b21b-785bb4e1558b.png?rdr=true"/></div>
                </div>
                <div className="intro" dangerouslySetInnerHTML={{__html: transformLineBreaks(props.data.intro)}}></div>

                <div className="title-prelude">
                    <strong>Enjoy &amp; Implement The <span className="underlined">Attached Resource</span> From Our Guest:</strong><br/>
                    <p className="title"><strong><em>&quot;{props.data.title}&quot;</em></strong></p>
                </div>
                <div className="spacer-thin">&nbsp;</div>
                <h2 className="author-title">ABOUT OUR GUEST</h2>
                <div className="bio" dangerouslySetInnerHTML={{__html: transformLineBreaks(props.data.bio)}}></div>

                <div className="call-to-action"><a>CLICK HERE TO LISTEN TO THIS EPISODE AGAIN!</a></div>
                <hr/>
                <div className="end-line">
                <div>ROCK-IT FUEL IS PROUDLY SPONSORED BY <a href="http://ketchwords.com">KETCHWORDS.COM</a></div>
                <div className="subline">Connecting High Impact Information With Action Takers</div>
                </div>

                <div className="footer">
                    <div className="separator"> </div>
                    <p><strong>You have texted a Ketchword.</strong><br/>
                    <strong>The educational Ketchword you subscribed to was <span className="ketchword">{props.data.ketchword.toLocaleUpperCase()}</span></strong></p>
                    <p></p>
                    <p>To stop receiving educational updates from <strong>{props.data.ketchword.toLocaleUpperCase()}</strong> (via text or email)<br/>
                    please text <strong>STOP {props.data.ketchword.toLocaleUpperCase()}</strong> to <strong>411321</strong>.<br/>
                    (or <strong>STOP {props.data.ketchword.toLocaleUpperCase()}</strong> to <strong>1-909-741-411321</strong> via text or WhatsApp)</p>
                </div>
                <div className="ketchwords-logo">
                    <img alt="Ketchwords Logo" src="https://files.constantcontact.com/bcd01438001/17feec14-0648-4821-8278-c9f421f09f34.png?rdr=true"/>
                    <p>Ketch is registered trademarks of Bull's Eye Innovations Corp.</p>
    <p>To learn more about this service for your business, visit <a href="http://ketchwords.com">Ketchwords.com</a></p>
    <p>100 Walnut Avenue  |  Suite 2010  |  Clark, NJ  |  07066  |   (908) 603-5401</p>
                </div>
            </div>
        </div>
    )
}

export default RockitFuelPreview;